import { store } from "@/store/store";
import { hist_contratos_empleado } from "@/shared/dtos/hist_contratos_empleado";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "hist_contratos_empleadoModule",
  store,
  dynamic: true,
})
class hist_contratos_empleadoModule extends VuexModule {
  public hist_contratos_empleados: hist_contratos_empleado[] = [];
  public hist_contratos_empleado: hist_contratos_empleado = new hist_contratos_empleado();

  @Action({ commit: "onGethist_contratos_empleados" })
  public async gethist_contratos_empleados() {
    return await ssmHttpService.get(API.hist_contratos_empleado);
  }

  @Action({ commit: "onGethist_contratos_empleado" })
  public async gethist_contratos_empleado(id: any) {
    return await ssmHttpService.get(API.hist_contratos_empleado + "/" + id);
  }

  @Action({ commit: "onGethist_contratos_empleados" })
  public async gethist_contratos_por_empleado(id: any) {
    return await ssmHttpService.get(API.hist_contratos_empleado + "/contratos/" + id);
  }

  @Action
  public async guardarhist_contratos_empleado(
    hist_contratos_empleado: hist_contratos_empleado
  ) {
    return await ssmHttpService.post(
      API.hist_contratos_empleado,
      hist_contratos_empleado.toJson()
    );
  }

  @Action
  public async modificarhist_contratos_empleado(
    hist_contratos_empleado: hist_contratos_empleado
  ) {
    return await ssmHttpService.put(
      API.hist_contratos_empleado + "/" + hist_contratos_empleado.id,
      hist_contratos_empleado
    );
  }

  @Action
  public async eliminarhist_contratos_empleado(
    hist_contratos_empleado: hist_contratos_empleado
  ) {
    return await ssmHttpService.delete(
      API.hist_contratos_empleado + "/" + hist_contratos_empleado.id,
      null,
      false
    );
  }

  @Mutation
  public onGethist_contratos_empleados(res: hist_contratos_empleado[]) {
    this.hist_contratos_empleados = res
      ? res.map((x) => new hist_contratos_empleado(x))
      : [];
  }

  @Mutation
  public onGethist_contratos_empleado(res: hist_contratos_empleado) {
    this.hist_contratos_empleado = new hist_contratos_empleado(res);
  }
}
export default getModule(hist_contratos_empleadoModule);
