import { BaseDto } from "@/shared/dtos/base-dto";
import { convenio } from "./convenio";
import { tipos_contrato } from "./tipos_contrato";

export class hist_contratos_empleado extends BaseDto {
  public fecha_inicio!: Date;
  public fecha_fin!: Date;
  public porcen_por_conv!: number;
  public id_contrato!: number;
  public id_convenio!: number;
  public contrato!: tipos_contrato;
  public convenio!: convenio;
  public id_empleado!: number;
  public id_empresa!: number;
}
