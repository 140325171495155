// Codigo de teclas pulsadas
// para mas teclas --> https://keycode.info/
export class KeyboardKeyCodes {
    public static backspace: number = 8;
    public static tab: number = 9;
    public static enter: number = 13;
    public static shift: number = 16;
    public static ctrl: number = 17;
    public static alt: number = 18;
    public static pause_break: number = 19;
    public static caps_lock: number = 20;
    public static escape: number = 27;
    public static page_up: number = 33;
    public static Space: number = 32;
    public static page_down: number = 34;
    public static end: number = 35;
    public static home: number = 36;
    public static arrow_left: number = 37;
    public static arrow_up: number = 38;
    public static arrow_right: number = 39;
    public static arrow_down: number = 40;
    public static print_screen: number = 44;
    public static insert: number = 45;
    public static delete: number = 46;
    public static numero_0: number = 48;
    public static numero_1: number = 49;
    public static numero_2: number = 50;
    public static numero_3: number = 51;
    public static numero_4: number = 52;
    public static numero_5: number = 53;
    public static numero_6: number = 54;
    public static numero_7: number = 55;
    public static numero_8: number = 56;
    public static numero_9: number = 57;
    public static a: number = 65;
    public static b: number = 66;
    public static c: number = 67;
    public static d: number = 68;
    public static e: number = 69;
    public static f: number = 70;
    public static g: number = 71;
    public static h: number = 72;
    public static i: number = 73;
    public static j: number = 74;
    public static k: number = 75;
    public static l: number = 76;
    public static m: number = 77;
    public static n: number = 78;
    public static o: number = 79;
    public static p: number = 80;
    public static q: number = 81;
    public static r: number = 82;
    public static s: number = 83;
    public static t: number = 84;
    public static u: number = 85;
    public static v: number = 86;
    public static w: number = 87;
    public static x: number = 88;
    public static y: number = 89;
    public static z: number = 90;
    public static ñ: number = 192;
    public static left_window_key: number = 91;
    public static right_window_key: number = 92;
    public static select_key: number = 93;
    public static numpad_0: number = 96;;
    public static numpad_1: number = 97;
    public static numpad_2: number = 98;
    public static numpad_3: number = 99;
    public static numpad_4: number = 100;
    public static numpad_5: number = 101;
    public static numpad_6: number = 102;
    public static numpad_7: number = 103;
    public static numpad_8: number = 104;
    public static numpad_9: number = 105;
    public static multiply: number = 106;
    public static add: number = 107;
    public static subtract: number = 109;
    public static decimal_point: number = 110;
    public static divide: number = 111;
    public static f1: number = 112;
    public static f2: number = 113;
    public static f3: number = 114;
    public static f4: number = 115;
    public static f5: number = 116;
    public static f6: number = 117;
    public static f7: number = 118;
    public static f8: number = 119;
    public static f9: number = 120;
    public static f10: number = 121;
    public static f11: number = 122;
    public static f12: number = 123;
    public static num_lock: number = 144;
    public static scroll_lock: number = 145;
    public static My_Computer_multimedia_keyboard: number = 182;
    public static My_Calculator_multimedia_keyboard: number = 183;
    public static semi_colon: number = 186;
    public static equal_sign: number = 187;
    public static comma: number = 188;
    public static dash: number = 189;
    public static period: number = 190;
    public static forward_slash: number = 191;
    public static open_bracket: number = 219;
    public static back_slash: number = 220;
    public static close_braket: number = 221;
    public static single_quote: number = 222;
}