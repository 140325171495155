



















import { Component, Mixins } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import usuarioModule from "@/store/modules/usuario-module";
import { Usuario } from "@/shared/dtos/usuario";
import hist_contratos_empleadoModule from "@/store/modules/hist_contratos_empleado-module";
import { RouterNames } from "@/router/routernames";
import { changepwd } from "@/shared/dtos/changepwd";

@Component({
  components: {},
})
export default class AdminUsuarioFormulario extends Mixins(
  HelperDinamicallyForm
) {
  public item_pantalla: Usuario = new Usuario();
  public contra_anterior:string = "";

  public async OnCreated() {
    this.ShowLoading();

    if (this.getparamId().HayParametro) {
      await usuarioModule.getusuario_plataforma(this.getparamId().Parametro);
      this.item_pantalla = usuarioModule.usuario;
      await usuarioModule
        .getusuario_pwd(this.getparamId().Parametro)
        .then((x) => {
          this.item_pantalla.contra = x;
          this.contra_anterior=x;
        });
    }
    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
    this.HideLoading();
  }

  open_form_contrato(id: string) {
    if (this.EstoyCreado) {
      alert("Guarde el usuario antes de crear un contrato");
      return;
    }
    this.$router.push({
      name: RouterNames.contratos_empleado,
      params: { id: id, id_empleado: this.getparamId().Parametro.toString() },
    });
  }

  async refresh_hist_contratos() {
    await hist_contratos_empleadoModule.gethist_contratos_por_empleado(
      this.getparamId().Parametro
    );
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    this.AddFormItemDataDefaultString(
      this.item_pantalla.nombre,
      "Nombre",
      "nombre",
      60
    )
      .isRequired()
      .Dimesiones(12, 4, 2)
      .isReadOnly();

    this.AddFormItemDataDefaultString(this.item_pantalla.nif, "Nif", "nif", 50)
      .Dimesiones(12, 4, 2)
      .isRequired()
      .CrearValidacion("nie")
      .isReadOnly();

    this.AddFormItemDataDefaultString(
      this.item_pantalla.email,
      "Email",
      "email",
      50
    )
      .Dimesiones(12, 6, 4)
      .isRequired()
      .CrearValidacion("email")
      .isReadOnly();
    this.AddFormItemDataDefaultString(
      this.item_pantalla.num_seg_social,
      "Número de seguridad social",
      "num_seg_social",
      50
    )
      .Dimesiones(12, 6, 4)
      .isReadOnly();

    this.AddFormItemDataDefaultString(
      this.item_pantalla.contra,
      "Contraseña",
      "contra"
    ).Dimesiones(12, 6, 4);
  }

  public Actualizar(object: Usuario) {
    var obj: changepwd = new changepwd();
    obj.contra = this.contra_anterior;
    obj.nuevacontra = object.contra;
    obj.rptnuevacontra  = object.contra;
    obj.id= this.item_pantalla.id;
    usuarioModule.sudochange_pwdusuario(obj).then(() => {
      this.AllSaveOk(); //guardo correctamente y me cierro
    });
  }
}
