import { UtilsString } from "./utils-string";

export class UtilsBoolean {
  public static Anytoboolean(value: any): boolean {
    if (value === undefined) {
      return false;
    }
    if (UtilsString.IsNullOrWhiteSpace(value)) {
      return false;
    }
    if (Number.isInteger(value)) {
      if (Number.parseInt(value) > 0) {
        return true;
      } else {
        return false;
      }
    }
    if (value.toString().toLowerCase()  === "true") {
      return true;
    }
    if (value.toString().toLowerCase()  === "false") {
      return false;
    }
    if (UtilsString.ValueOf(value).toLowerCase() === "Si".toLowerCase()) {
      return true;
    }
    if (UtilsString.ValueOf(value).toLowerCase() === "No".toLowerCase()) {
      return false;
    }
    return false;
  }
}
