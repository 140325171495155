import { UtilsString } from "./utils-string";

export class UtilsNumber {
  public static between(
    value: number,
    valor_1_peq: number,
    valor_2_may: number
  ): boolean {
    if (value >= valor_1_peq && value <= valor_2_may) {
      return true;
    }
    return false;
  }

  public static RoudTwoDecimals(num: number): number {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  public static numberToStringFormat(data: any, formatzeros: number) {
    let dataaux = UtilsString.ValueOf(data);
    let zeros = formatzeros - dataaux.length;
    for (let i = 0; i < zeros; i++) {
      dataaux = "0" + dataaux;
    }
    return dataaux;
  }
  public static roundToTwo(num: number) {
    //@ts-ignore
    return +(Math.round(num + "e+2") + "e-2");
  }
  public static formatNumberWhitZero(num: number) {
    if (UtilsString.ValueOf(num) === "") {
      return "0";
    }
    if (num <= 9) {
      return "0" + num.toString();
    }
    return num.toString();
  }
  public static formatWhitCeroLast(num: number) {
    if (UtilsString.ValueOf(num) === "NaN") {
      return "0,00";
    }
    if (UtilsString.ValueOf(num) === "") {
      return "0,00";
    }

    num = UtilsNumber.roundToTwo(num);
    //@ts-ignore
    let strnumero = num.toString();
    try {
      if (num.toString().split(".")[1].length === 1) {
        strnumero = strnumero + "0";
      }
    } catch (error) {
      strnumero = strnumero + ".00";
    }
    if (strnumero === ".00") {
      strnumero = "0.00";
    }
    return strnumero.replace(".", ",");
  }
}
